<!-- View to add/modify feature positions and pages -->
<template>
    <v-card color="fill" flat class="pb-0 mb-0 pt-0">
    <v-container fluid class="ma-0 pa-0" v-resize='onResize'>
      <v-card-title class="accent--text font-weight-normal">
        Feature Position Management
        <v-spacer/>
      </v-card-title>
        <v-row dense>
            <v-col cols='2'>
                <span class='text-caption font-weight-light font-italic mx-2'>Click Page Name to Edit</span>
            </v-col>
            <v-col cols='2'>
                <span class='text-caption font-weight-light font-italic mx-2'>Click Feature Position to Edit</span>
            </v-col>
        </v-row>
        <v-row class='fill-height'>
          <v-col cols='2' class="pt-1 pb-0 px-0">
            <v-toolbar height="32" flat class="accent white--text">
              <v-toolbar-title class="text-caption font-weight-bold">Pages</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical background-color="fill" color="primary" light class="pt-0"
              hide-slider :style="tabsHeight" v-model="tab">
              <v-tab v-for="(page, index) in pages" :key="index" @change="adPage=page" cols='3'
                class="text-capitalize font-weight-normal text-body-2">
                <span v-if='!(editPage=== page)' :class="adPage===page ? 'font-weight-bold' : 'font-weight-normal'"> {{ page.name }}
                </span>
                <v-text-field class='mx-0 pt-3 pb-0 px-0' v-if='editPage === page' v-model='page.name'>
                    <!-- <template v-slot:append>        
                        <v-icon dense @click.stop='editPage = 0'>mdi-close</v-icon> 
                    </template> -->
                </v-text-field>
                <v-spacer/>
                <v-icon v-if="adPage===page">mdi-chevron-right</v-icon>
              </v-tab>
              <v-tab :key='addPageField' @click='addPageField= true'>
                  <v-text-field v-if='addPageField' v-model='name' @keydown.enter="addPage(name), addPageField=false">
                    <template v-slot:append>        
                        <v-icon dense @click.stop='addPageField = false'>mdi-close</v-icon> 
                    </template>
                    </v-text-field>
                  <span v-if='!addPageField'>Add New Page</span>
                  <v-icon v-if='!addPageField'>mdi-plus</v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col class="mt-1 pt-0 px-0" :style="colBorder">
            <v-data-table :headers="headers" :items.sync="pagePositions" :search="search"
              :loading="loading" item-key="id" :class="table" dense fixed-header :items-per-page="100"
              :height="tableHeight" hide-default-footer class="pa-0 ma-0" :item-class="itemRowBackground">

              <template #[`item.name`]="{item}">
                  <span v-if="edit !== item" @click.stop='edit = item'>{{item.name}}</span>
                  <v-row dense>
                      <v-col cols='3'>
                  <v-text-field dense class='pt-2' v-if='edit === item' v-model='item.name' @keydown.enter="updateFeatPosition(item), edit=false">
                    <template v-slot:append>        
                        <v-icon dense @click.stop='edit = {}'>mdi-close</v-icon> 
                    </template>
                    </v-text-field>
                    </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-dialog v-model='addPosition' width="600px">
            <v-card color="fill" fluid>
            <v-card-title class='accent--text'>Add New Position</v-card-title>
            <v-row dense class='mx-3'>
            <v-col cols='3'>
            <v-select :items='pages' item-text="name" v-model='newPosPage' placeholder='Select Page' dense outlined return-object></v-select>
            </v-col>
            <v-col cols='8'>
            <v-text-field v-model='newPosName' dense outlined placeholder="Enter Position Name (e.g Box 11)"></v-text-field>
            </v-col>
            </v-row>
            <v-card-actions>
            <v-row dense justify='end' class='pb-2'>
            <v-btn text :class="body" @click="addPosition = false">
            Cancel
            </v-btn>
            <v-btn  color="save" :class="body" class="white--text ml-2"
             :loading="loading"
              @click="addFeatPosition()">
            Save
            </v-btn>
            </v-row>
            </v-card-actions>
            </v-card>
        </v-dialog>
        
    </v-container>
    <v-fab-transition>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn v-on="on" :style="floatingButton" absolute bottom right fab class="primary mr-6 mb-0" @click="addPosition = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create Feature Position</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'

export default {
  name: 'FeaturePositionManager',

  props: [
    'value',
    'defaultParty'
  ],

  mixins: [displayHelpers],

  data: () => ({
    adPage: {},
    addPageField: false,
    addPosition:false,
    existingFeatures: [],
    editPage: 0,
    editPosName: '',
    edit: {},
    loading: false,
    name: null,
    newPage:{},
    newPosPage:{},
    newPosName:'',
    page: {},
    pagePositions: [],
    search: '',
    selected: [],
    tab: 0,
    tableHeight: '1200',
    layout: {},
    layoutFeatures: []
  }),

  computed: {

    headers () {
      return [
        { text: 'Feature Position', sortable: true, filterable: true, class: 'accent white--text', value: 'name' }
      ]
    },

    tabsHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return 'height:610px;'
        case 'lg': return 'height:500px;'
        case 'md': return 'height:485px;'
        default: return '5em';
      }
    },

    colBorder () {
      return `
        border:1px solid #d7d7d7;
        border-bottom: none;
        border-top: none;
      `
    },

    addPageBool(){
        return this.addPageField
    },
    
    floatingButton () {
      return `
        bottom: 25px;
        right: 5px; 
      `
    },

    pages () {
      return this.$store.getters.adPages
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.tableHeight = window.innerHeight - 220
          this.getAdPages()
        }
      }
    },


    adPage: {
      handler (newValue, oldValue) {
        // if (this.isExistingTemplate) this.getExistingFeatures()
        if (!this._.isEmpty(oldValue) && !this._.isEmpty(newValue))  {
          // this.saveFeatures(oldValue)
          this.getPagePositions()
        }
      }
    }
  },

  created() {
      this.getAdPages()
  },
  
  methods: {
    onResize () {
      this.tableHeight = window.innerHeight - 220
    },

    itemRowBackground (item) {
      return this.pagePositions.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },

    async getAdPages () {
      this.adPage = this.pages.find(page => page.constant === 'PAGE_1')
      await this.getPagePositions()
    },

    async getPagePositions () {
      this.loading = true
      await this.$KeyValues.getPagePositions(this.adPage.constant)
        .then(res => {
          this.pagePositions = res.data
          this.loading = false
        })
    },

    async updatePage(page){
        await this.$KeyValues.updateKeyValue(page).then(res=>{
            if(res.status == 200){
            this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Updated` })
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Ad Pages due to ${err?.response?.data?.message || err.message}` })
        })
    },
  
    async addPage(name){
        this.newPage= {key:{id:'AD_PAGES'}, constant:name.split(' ').join('_').toUpperCase(), name:name, display_order:this.pages[this.pages.length-1].display_order + 100 }
        await this.$KeyValues.addKeyValue(this.newPage).then(res=>{
            if(res.status == 201){
                this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Added` })
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Add Page due to ${err?.response?.data?.message || err.message}` })
        }).finally(this.addPageField = false)
        const key = {id:`PAGE_POSITION_${name.split(' ').join('_').toUpperCase()}`, name:`Page Position ${name}`}
        await this.$KeyValues.addKey(key).then(res=>{
            if(res.status == 201){
                this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Added` })
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Add Page due to ${err?.response?.data?.message || err.message}` })
        })
        this.getAdPages()
        
    },

    async addFeatPosition(){
        this.loading=true
        this.adPage = this.newPosPage
        await this.getPagePositions()
        if(this.pagePositions.length > 0){
        this.loading=true
        const newPos = {
        key:{id:`PAGE_POSITION_${this.newPosPage.constant}`},
        constant:this.newPosName.split(' ').join('_').toUpperCase(),
        name:this.newPosName,
        display_order:this.pagePositions[this.pagePositions.length-1].display_order + 100 }
        await this.$KeyValues.addKeyValue(newPos).then(res=>{
            if(res.status == 201){
            this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Added` })
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Add Position due to ${err?.response?.data?.message || err.message}` })
        }).finally(this.loading = false, this.addPosition=false)
        this.getPagePositions()
        }
        else{
        const newPos = {
        key:{id:`PAGE_POSITION_${this.newPosPage.constant}`},
        constant:this.newPosName.split(' ').join('_').toUpperCase(),
        name:this.newPosName,
        display_order:100 }
        await this.$KeyValues.addKeyValue(newPos).then(res=>{
            if(res.status == 201){
            this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Added`})
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Add Position due to ${err?.response?.data?.message || err.message}` })
        }).finally(this.loading = false, this.addPosition=false)
        this.getPagePositions()
        }
    },

    async updateFeatPosition(position){
        this.loading = true
        await this.$KeyValues.updateKeyValue(position).then(res=>{
            if(res.status == 200){
                this.$store.dispatch('setSnackbar', { status: 'success', text: `Successfully Updated` })
            }
        }).catch(err=>{
            this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Add Position due to ${err?.response?.data?.message || err.message}` })
        }).finally(this.loading = false)
        this.getPagePositions()
    }
    
  
  }
}
</script>
<style scoped>
  .active {
    font-weight: bold;
    background: #e0e0e0;
    color: #000 !important;
  }
</style>